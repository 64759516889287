import { calendarFunctions } from "./nepaliDatePicker.min.js"

//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
// NEPALI CALENDAR STUFF
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
let datefmt = "%y-%m-%d";
//frappe.ui.form.ControlDate = frappe.ui.form.ControlDate.extend({
frappe.ui.form.ControlDate = class ControlDate extends frappe.ui.form.ControlDate {
	make_input() {
		super.make_input();
		this.make_picker();
		this.add_nepali_calendar();
	}
	updateAdDate(ancestor, event) {
		var adInput = $(ancestor).find('input[data-fieldtype="Date"]');
	
		// check to see if the input is available and active; if not, reset Bs date back to the appropriate value
		if ($(adInput).length == 0 || $(adInput).prop('disabled')) {
			updateBsDate(ancestor, null);
		}
		else {
			var field = $(ancestor).attr("data-fieldname");
			cur_frm.doc[field] = frappe.datetime.obj_to_str(event.datePickerData.adDate);
			cur_frm.refresh_field(field);
		}
	}
	updateBsDate(ancestor, event) {
		setTimeout(function() {
			var field = $(ancestor).attr("data-fieldname");
			var adDate = cur_frm.doc[field];
			if (adDate) {
				var dateObj = frappe.datetime.str_to_obj(adDate);
				var nepObj = calendarFunctions.getBsDateByAdDate(dateObj.getFullYear(), dateObj.getMonth()+1, dateObj.getDate());
				var formattedBs = calendarFunctions.bsDateFormat(datefmt, nepObj.bsYear, nepObj.bsMonth, nepObj.bsDate)
				$(ancestor).find(".nepDate").val(formattedBs);
			}
		},100)	
	}
	add_nepali_calendar() {
		//console.log(this.$input)
		//setup wrapper as relative to allow absolute positioning of Nepali date field
		var me = this;
		$(this.$input).parents('.control-input-wrapper').css('position', 'relative')

		// add nepali date input and initialize
		var input_parent = $(this.$input).parents('.frappe-control[data-fieldtype="Date"]')
		input_parent.find('.control-input').after(`
			<input placeholder="&#x1F1F3&#x1F1F5" class='nepDate'>
		`);
		input_parent.find('.nepDate').nepaliDatePicker({
			dateFormat: datefmt,
			closeOnDateSelect: true
		});

		// add change triggers to inputs
		input_parent.find(".nepDate").on("dateChange", function (event) {
			var ancestorController = $(this).parents(".frappe-control");
			me.updateAdDate(ancestorController, event);
		});
		input_parent.find('input[data-fieldtype="Date"]').on('blur', function(event) {
			var ancestorController = $(this).parents(".frappe-control");
			me.updateBsDate(ancestorController, event);
		});
		input_parent.find('.frappe-control[data-fieldtype="Date"]').each(function() {
			me.updateBsDate(this, null);
		})


	}
}


//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
// MAINTENANCE VIEW CALENDAR
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
frappe.views.calendar["Maintenance Visit"] = {
	field_map: {
		"start": "start",
		"end": "end",
		"doctype": "doctype",
		"id": "name",
		"allDay": "all_day",
		"title": "customer",
		"status": "workflow_state",
		//"color": "color",
		"backgroundColor": "backgroundColor",
		"borderColor": "borderColor",
		"textColor": "textColor",
		"tooltip": "tooltip"
	},
	get_events_method: "nepalerp.get_maintenance_visit_dates",
	update_event_method: "nepalerp.update_maintenance_visit_date",
	defaultView: 'month',
	header: {
		left: 'prev, title, next',
		right: 'today, month, agendaWeek, agendaDay, listMonth'
	},
}

//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
// CALENDAR OVERRIDES
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########//##########
// frappe.views.Calendar = frappe.views.Calendar.extend({
// 	init: function(options) {
// 		console.log(options);
// 		$.extend(this, options);
// 		this.get_default_options();
// 		console.log(this.header)
// 	},
// 	setup_options: function(defaults) {
// 		var me = this;
// 		defaults.meridiem = 'false';
// 		this.cal_options = {
// 			locale: frappe.boot.user.language || "en",
// 			header: me.header || {
// 				left: 'prev, title, next',
// 				right: 'today, month, agendaWeek, agendaDay, listMonth'
// 			},
// 			editable: true,
// 			selectable: true,
// 			selectHelper: true,
// 			forceEventDuration: true,
// 			displayEventTime: true,
// 			defaultView: me.defaultView || defaults.defaultView,
// 			weekends: defaults.weekends,
// 			nowIndicator: true,
// 			events: function(start, end, timezone, callback) {
// 				return frappe.call({
// 					method: me.get_events_method || "frappe.desk.calendar.get_events",
// 					type: "GET",
// 					args: me.get_args(start, end),
// 					callback: function(r) {
// 						var events = r.message || [];
// 						events = me.prepare_events(events);
// 						callback(events);
// 					}
// 				});
// 			},
// 			displayEventEnd: true,
// 			eventRender: function(event, element) {
// 				element.attr('title', event.tooltip);
// 			},
// 			eventClick: function(event) {
// 				// edit event description or delete
// 				var doctype = event.doctype || me.doctype;
// 				if(frappe.model.can_read(doctype)) {
// 					frappe.set_route("Form", doctype, event.name);
// 				}
// 			},
// 			eventDrop: function(event, delta, revertFunc) {
// 				me.update_event(event, revertFunc);
// 			},
// 			eventResize: function(event, delta, revertFunc) {
// 				me.update_event(event, revertFunc);
// 			},
// 			select: function(startDate, endDate, jsEvent, view) {
// 				if (view.name==="month" && (endDate - startDate)===86400000) {
// 					// detect single day click in month view
// 					return;
// 				}

// 				var event = frappe.model.get_new_doc(me.doctype);

// 				event[me.field_map.start] = me.get_system_datetime(startDate);

// 				if(me.field_map.end)
// 					event[me.field_map.end] = me.get_system_datetime(endDate);

// 				if(me.field_map.allDay) {
// 					var all_day = (startDate._ambigTime && endDate._ambigTime) ? 1 : 0;

// 					event[me.field_map.allDay] = all_day;

// 					if (all_day)
// 						event[me.field_map.end] = me.get_system_datetime(moment(endDate).subtract(1, "s"));
// 				}

// 				frappe.set_route("Form", me.doctype, event.name);
// 			},
// 			dayClick: function(date, jsEvent, view) {
// 				if(view.name === 'month') {
// 					const $date_cell = $('td[data-date=' + date.format('YYYY-MM-DD') + "]");

// 					if($date_cell.hasClass('date-clicked')) {
// 						me.$cal.fullCalendar('changeView', 'agendaDay');
// 						me.$cal.fullCalendar('gotoDate', date);
// 						me.$wrapper.find('.date-clicked').removeClass('date-clicked');

// 						// update "active view" btn
// 						me.$wrapper.find('.fc-month-button').removeClass('active');
// 						me.$wrapper.find('.fc-agendaDay-button').addClass('active');
// 					}

// 					me.$wrapper.find('.date-clicked').removeClass('date-clicked');
// 					$date_cell.addClass('date-clicked');
// 				}
// 				return false;
// 			}
// 		};

// 		if(this.options) {
// 			$.extend(this.cal_options, this.options);
// 		}
// 	},
// 	prepare_events: function(events) {
// 		var me = this;

// 		return (events || []).map(d => {
// 			d.id = d.name;
// 			d.editable = frappe.model.can_write(d.doctype || me.doctype);

// 			// do not allow submitted/cancelled events to be moved / extended
// 			if(d.docstatus && d.docstatus > 0) {
// 				d.editable = false;
// 			}

// 			$.each(me.field_map, function(target, source) {
// 				d[target] = d[source];
// 			});

// 			if(!me.field_map.allDay)
// 				d.allDay = 1;

// 			// convert to user tz
// 			d.start = frappe.datetime.convert_to_user_tz(d.start);
// 			d.end = frappe.datetime.convert_to_user_tz(d.end);

// 			// show event on single day if start or end date is invalid
// 			if (!frappe.datetime.validate(d.start) && d.end) {
// 				d.start = frappe.datetime.add_days(d.end, -1);
// 			}

// 			if (d.start && !frappe.datetime.validate(d.end)) {
// 				d.end = frappe.datetime.add_days(d.start, 1);
// 			}

// 			me.fix_end_date_for_event_render(d);
// 			//me.prepare_colors(d);

// 			d.title = frappe.utils.html2text(d.title);

// 			return d;
// 		});
// 	}
// });
