import "./nepaliCalendar.js";
import "./bootstrap-slider.js";

var Slider = require("./bootstrap-slider.js");
window.Slider = Slider;

$(document).on('app_ready', function() {
	console.log("Thank you for using NepalERP!");
});

frappe.provide("frappe.ui.toolbar");
frappe.ui.toolbar.new_window = function (destination) {
	let website_tab = window.open();
	website_tab.opener = null;
	website_tab.location = destination;
};
